import runTests from './run-tests';

export const keys = [
  79, // O
  71, // G
  68, // D
  69, // E
  86 // V
];

export const enable = () => {
  if (window.OG_OFFERS_TEST_MODE_ENABLE) return;
  window.OG_OFFERS_TEST_MODE_ENABLE = true;
  let keysCounter = 0;
  document.addEventListener(
    'keyup',
    async function(e) {
      const key = e.which;
      if (key === keys[keysCounter]) {
        const currentkeys = keys[keysCounter];
        setTimeout(function() {
          if (keysCounter <= currentkeys) keysCounter = 0;
        }, 5000);
        keysCounter += 1;
        if (keysCounter >= keys.length) {
          runTests();
        }
      } else {
        keysCounter = 0;
      }
    },
    false
  );
};

export default enable;
