import { html } from 'lit-element';
import { connect } from '../core/connect';

import { withProduct } from '../core/resolveProperties';
import { TemplateElement } from '../core/base';
import { makeProductFrequencySelector } from '../core/selectors';
import { safeProductId } from '../core/utils';

export class Price extends withProduct(TemplateElement) {
  static get properties() {
    return {
      ...super.properties,
      regular: { type: Boolean, reflect: true },
      subscription: { type: Boolean, reflect: true },
      discount: { type: Boolean, reflect: true },
      payAsYouGo: { type: Boolean, reflect: true, attribute: 'pay-as-you-go' },
      frequency: { type: Object },
      productPlans: { type: Object }
    };
  }

  get value() {
    const realProductId = safeProductId(this.product);
    const frequency = this.frequency || this.configDefaultFrequency || this.offer?.defaultFrequency;
    const plans = this.productPlans[realProductId] || [];

    if (this.payAsYouGo) {
      const payAsYouGoPlan = plans.find(plan => plan.prepaidShipments === null || plan.prepaidShipments === undefined);
      if (!payAsYouGoPlan) return '';
      return payAsYouGoPlan.subscriptionPrice;
    }

    const currentPlan = plans.find(plan => plan.frequency === frequency);
    if (!currentPlan) return '';
    const { regularPrice, discountRate, subscriptionPrice } = currentPlan;

    if (subscriptionPrice === regularPrice) return '';

    if (this.regular) {
      return regularPrice;
    }
    if (this.discount) return discountRate;
    return subscriptionPrice;
  }

  render() {
    const value = this.value;
    if (value)
      return html`
        <slot name="prepend"></slot>
        ${value}
        <slot name="append"></slot>
      `;

    return html`
      <slot name="fallback"></slot>
    `;
  }
}
const mapStateToProps = (state, ownProps) => ({
  productPlans: state.productPlans,
  configDefaultFrequency: state.config?.defaultFrequency,
  frequency: makeProductFrequencySelector(ownProps.product)(state)
});

export default connect(mapStateToProps)(Price);
