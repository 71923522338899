import { OPTIN_PRODUCT, OPTOUT_PRODUCT, PRODUCT_CHANGE_FREQUENCY, RECEIVE_OFFER } from '../core/constants';
import { getTrackingEvent, getSubscribedFrequency } from './shopifyMiddleware';

/**
 * Creates or updates a hidden input used for tracking on non-cart pages
 *
 * @param  product_id a product ID
 * @param  name an input name, og_<timestamp in seconds>
 * @param  value an input value, <tracking event>
 * @return {undefined}
 */
export function updateTrackingInputs(product_id: string, name: string, value: string) {
  const store2FormElementSelector = `[name="id"][value="${product_id}"]`;
  const store1FormElementSelector = `form[action="/cart/add"] option[value="${product_id}"]`;
  if (!name) return;
  let cartAddFormElements = document.querySelectorAll(store2FormElementSelector);
  if (!cartAddFormElements.length) {
    cartAddFormElements = document.querySelectorAll(store1FormElementSelector);
  }
  [...cartAddFormElements].forEach((cartAddFormElement: HTMLInputElement) => {
    const parent = cartAddFormElement.form;

    let input = parent?.querySelector(`[name="${name}"]`) as HTMLInputElement;
    if (!input) {
      input = document.createElement('input');
      input.type = 'hidden';
      input.name = `attributes[${name}]`;
      parent?.appendChild(input);
    }
    input.value = value;
  });
}

export function getTrackingKey() {
  return `og__${Math.ceil(new Date().getTime() / 1000)}`;
}

export function addDefaultToSubTracking(action, store) {
  // check if default to sub
  const isDefaultToSub = action.payload.offer?.autoshipByDefault;
  if (!isDefaultToSub) return;

  // if default to sub, get tracking info
  const productId = action.payload.offer?.product.id;
  const key = getTrackingKey();
  const location = action.payload.offer?.location || '';
  const variation = action.payload.offer?.variationId || '';
  const frequency = getSubscribedFrequency(productId, store);
  const value = [productId, OPTIN_PRODUCT.toLowerCase(), location, frequency, variation];
  const inputValue = value.join(',');
  updateTrackingInputs(productId, key, inputValue);
    
}

export default function shopifyTrackingMiddleware(store) {
  return next => action => {
    next(action);

    switch (action.type) {
      case OPTIN_PRODUCT:
      case OPTOUT_PRODUCT:
      case PRODUCT_CHANGE_FREQUENCY:
        const offerElement = action.payload.offer;
        const trackingEvent = getTrackingEvent(action);

        if (offerElement && !offerElement.isCart) {
          updateTrackingInputs(offerElement.product.id, trackingEvent[0], trackingEvent[1]);
        }
        break;
      case RECEIVE_OFFER:
        addDefaultToSubTracking(action, store);
        break;
      default:
    }
  };
}
