import { LitElement, html, css } from 'lit-element';

export class Tooltip extends LitElement {
  static get properties() {
    return {
      placement: { type: String, default: 'bottom' }
    };
  }

  static get styles() {
    return css`
      :host[hidden] {
        display: none;
      }

      :host {
        display: inline-block;
        position: relative;
        z-index: 9;
      }

      .trigger {
        display: block;
        cursor: pointer;
      }

      .content {
        box-sizing: border-box;
        font-family: var(--og-tooltip-family, inherit);
        font-size: var(--og-tooltip-size, inherit);
        color: var(--og-tooltip-color, inherit);
        background-color: var(--og-tooltip-background, #ececec);
        box-shadow: var(--og-tooltip-box-shadow, 2px 2px 6px rgba(0, 0, 0, 0.28));
        display: block;
        opacity: 0;
        padding: var(--og-tooltip-padding, 0.5em);
        text-align: var(--og-tooltip-text-align, left);
        pointer-events: none;
        position: absolute;
        transform: translateY(10px);
        transition: transform 0.25s ease-out;
        z-index: 99999;
        border-radius: var(--og-tooltip-border-radius, 0);
      }

      .content:after {
        content: ' ';
        height: 0;
        position: absolute;
        width: 0;
      }

      .top {
        bottom: 100%;
        margin-bottom: 10px;
      }

      .bottom {
        top: 100%;
        margin-top: 10px;
      }

      .left {
        right: 100%;
        margin-right: 10px;
      }

      .right {
        left: 100%;
        margin-left: 10px;
      }

      .top-left {
        bottom: 100%;
        margin-bottom: 10px;
        right: 100%;
        margin-right: -16px;
      }

      .top-right {
        bottom: 100%;
        margin-bottom: 10px;
        left: 100%;
        margin-left: -16px;
      }

      .bottom-left {
        top: 100%;
        margin-top: 10px;
        right: 100%;
        margin-right: -16px;
      }

      .bottom-right {
        top: 100%;
        margin-top: 10px;
        left: 100%;
        margin-left: -16px;
      }

      .bottom-left:after,
      .bottom-right:after,
      .top-left:after,
      .top-right:after,
      .top:after,
      .bottom:after {
        margin-left: -10px;
        left: 50%;
        border-left: solid transparent 10px;
        border-right: solid transparent 10px;
      }

      .top-left:after,
      .top-right:after,
      .top:after {
        bottom: -10px;
        border-top: solid var(--og-tooltip-background, #ececec) 10px;
      }
      .bottom-left:after,
      .top-left:after {
        left: auto;
        right: 0;
      }

      .bottom-right:after,
      .top-right:after {
        left: 0;
        right: auto;
        margin-left: 0;
      }

      .bottom-left:after,
      .bottom-right:after,
      .bottom:after {
        top: -10px;
        border-bottom: solid var(--og-tooltip-background, #ececec) 10px;
      }

      .left:after,
      .right:after {
        margin-top: -10px;
        top: 50%;
        border-top: solid transparent 10px;
        border-bottom: solid transparent 10px;
      }
      .right:after {
        left: -10px;
        border-right: solid var(--og-tooltip-background, #ececec) 10px;
      }
      .left:after {
        right: -10px;
        border-left: solid var(--og-tooltip-background, #ececec) 10px;
      }

      .tooltip:hover .content {
        opacity: 1;
        width: 200px;
        pointer-events: auto;
        transform: translateY(0px);
      }
    `;
  }

  connectedCallback() {
    super.connectedCallback();
    this.showTooltip = this.showTooltip.bind(this);
    this.addEventListener('mouseenter', this.showTooltip);
  }

  showTooltip() {
    const trigger = this.shadowRoot.querySelector('.trigger');
    const triggerRect = trigger.getBoundingClientRect();
    const content = this.shadowRoot.querySelector('.content');
    const contentRect = content.getBoundingClientRect();
    if (!this.placement || this.placement === 'top' || this.placement === 'bottom')
      content.style.left = `${(-1 * contentRect.width + triggerRect.width) / 2}px`;
    else if (this.placement === 'left' || this.placement === 'right')
      content.style.top = `${(-1 * contentRect.height + triggerRect.height) / 2}px`;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('mouseenter', this.showTooltip);
    this.removeEventListener('mouseout', this.hideTooltip);
  }

  render() {
    return html`
      <span class="tooltip">
        <span class="trigger">
          <slot name="trigger">
            ${this.trigger}
          </slot>
        </span>
        <div class="content ${this.placement || 'bottom'}">
          <slot name="content">
            ${this.content}
          </slot>
        </div>
      </span>
    `;
  }
}

export default Tooltip;
