import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { loadState } from './localStorage';
import { dispatchMiddleware, localStorageMiddleware, offerEvents } from './middleware';
import { waitUntilOffersReady } from './waitUntilOffersReady';

export function makeStore(reducer, ...extraMiddlewares) {
  if (window.og && window.og.store) return window.og.store;

  const isPreviewMode = window.og && window.og.previewMode;

  const composeEnhancers =
    // eslint-disable-next-line no-underscore-dangle
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? // eslint-disable-next-line no-underscore-dangle
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          name: 'Ordergroove Offers'
        })
      : compose;

  const middlewares = [waitUntilOffersReady, thunk, dispatchMiddleware, offerEvents];

  let initial = {};

  if (!isPreviewMode) {
    try {
      initial = loadState();
      middlewares.push(localStorageMiddleware);
    } catch (err) {
      // localStorage not available during preview in sandbox
    }
  }

  const enhancer = composeEnhancers(applyMiddleware(...middlewares, ...extraMiddlewares.filter(it => it)));
  const store = createStore(reducer, initial, enhancer);

  window.og = window.og || {};
  window.og.store = store;
  return store;
}

export default makeStore;
