import { LitElement, css, html } from 'lit-element';

export class Select extends LitElement {
  static get styles() {
    return css`
      :host {
        display: inline-block;
        color: inherit;
        position: relative;
        height: 100%;
        cursor: inherit;
        font-family: inherit;
        font-weight: inherit;
      }
      select {
        font-weight: inherit;
        display: block;
        height: 100%;
        cursor: inherit;
        color: inherit;
        font-family: inherit;
        font-size: 1em;
        line-height: 1.3;
        padding: var(--og-select-padding, 0.4em 1.8em 0.3em 0.5em);
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        margin: 0;
        border: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
      }
      select::-ms-expand {
        display: none;
      }
      select:focus {
        outline: none;
      }
      select option {
        font-weight: inherit;
      }
      span {
        position: absolute;
        // background: white;
        color: inherit;
        fill: white;
        pointer-events: none;
        right: 0.3em;
        top: 50%;
        z-index: 1;
        font-size: 1em;
        line-height: 0.2em;
        transform: scaleY(0.5);
      }
    `;
  }

  static get properties() {
    return {
      options: { type: Array },
      selected: { type: String },
      ariaLabel: { type: String }
    };
  }

  render() {
    const handleOnChange = ev => this.onChange(ev);
    return html`
      <select @change="${handleOnChange}" aria-label="${this.ariaLabel}">
        ${this.options.map(
          option => html`
            <option
              value="${option.value}"
              ?selected=${option.value === this.selected}
              .selected=${option.value === this.selected}
              >${option.text}</option
            >
          `
        )}
      </select>
      <span>&#9660;</span>
    `;
  }
}

export default Select;
