import { html, css } from 'lit-element';
import memoize from 'lodash.memoize';
import { connect } from '../core/connect';
import { setPreview } from '../core/actions-preview';
import {
  fetchOffer,
  productHasChangedComponents,
  fetchOrders,
  optinProduct,
  setProductToSubscribe,
  setFirstOrderPlaceDate
} from '../core/actions';
import {
  isSameProduct,
  configSelector,
  templatesSelector,
  makeOptedoutSelector,
  makeProductFrequencySelector,
  makeProductDefaultFrequencySelector,
  optedinSelector,
  autoshipSelector,
  makeOptedinSelector
} from '../core/selectors';
import { product as productProp, auth as authProp } from '../core/props';
import { TemplateElement } from '../core/base';
import { onReady } from '../core/utils';
import { DEFAULT_OFFER_MODULE } from '../core/constants';

const memoizeKey = (...args) => JSON.stringify(args);

export const productAndComponents = memoize(
  (product, components) => Object.assign({ components }, product),
  memoizeKey
);

export class Offer extends TemplateElement {
  static get properties() {
    return {
      ...super.properties,

      config: { type: Object, attribute: false },
      product: productProp,
      productComponents: { type: Array, attribute: 'product-components' },
      offerId: { type: String, attribute: false },
      auth: authProp,
      preview: { type: String, attribute: 'preview', reflect: 'true' },
      location: { type: String },
      autoshipByDefault: { type: Boolean, attribute: 'autoship-by-default' },
      productDefaultFrequency: { type: String, attribute: false },
      locale: { type: Object, attribute: true },
      firstOrderPlaceDate: { type: String, attribute: 'first-order-place-date' },
      productToSubscribe: { type: String, attribute: 'product-to-subscribe' },
      subscribed: { type: Boolean, reflect: true },
      frequency: { type: String, reflect: true },
      productFrequency: { type: String },
      isCart: { type: Boolean, attribute: 'cart' },
      optedin: { type: Object },
      variationId: { type: String }
    };
  }

  firstUpdated() {
    const preview = Array.from(this.getAttributeNames()).find(it => it.startsWith('preview-'));
    if (preview === 'preview-standard-offer') this.preview = 'regular';
    else if (preview === 'preview-upsell-offer') this.preview = 'upsell';
    else if (preview === 'preview-subscribed-offer') this.preview = 'subscribed';
    else if (preview === 'preview-prepaid-offer') this.preview = 'prepaid';
  }

  static get styles() {
    return css`
      :host[hidden] {
        display: none;
      }

      :host {
        display: block;
      }

      :host {
        color: var(--og-global-color, #000);
        font-family: var(--og-global-family, inherit);
        font-size: var(--og-global-size, inherit);
        padding: var(--og-wrapper-padding, 10px 0);
        min-width: var(--og-wrapper-min-width, 0);
      }

      p {
        margin: 0 0 0.3em;
      }

      :host og-upsell-button button {
        font-family: var(--og-upsell-family, inherit);
        font-size: var(--og-upsell-size, inherit);
        background-color: var(--og-upsell-background, inherit);
        color: var(--og-upsell-color, inherit);
      }

      .og-modal__btn {
        font-size: var(--og-modal-button-size, 0.875rem);
        font-family: var(--og-modal-button-family, inherit);
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background-color: var(--og-modal-button-background, #e6e6e6);
        color: var(--og-modal-button-color, rgba(0, 0, 0, 0.8));
        border-radius: 0.25rem;
        border-style: none;
        border-width: 0;
        cursor: pointer;
        -webkit-appearance: button;
        text-transform: none;
        overflow: visible;
        line-height: 1.15;
        margin: 0;
        will-change: transform;
        -moz-osx-font-smoothing: grayscale;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        transition: -webkit-transform 0.25s ease-out;
        transition: transform 0.25s ease-out;
        transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
      }

      .og-modal__btn:focus,
      .og-modal__btn:hover {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
      }

      .og-modal__btn-primary {
        background-color: var(--og-confirm-button-background, #00449e);
        color: var(--og-confirm-button-color, #fff);
      }
    `;
  }

  static get initialTemplate() {
    return `
    <og-when test="regularEligible">
      <div>

        <og-optout-button>
          <og-text key="offerOptOutLabel"></og-text>
        </og-optout-button>
      </div>
      <div>
        <og-optin-button>
          <og-price discount>
            <span slot="prepend">Subscribe and get</span>
            <span slot="append">off</span>
            <og-text key="offerOptInLabel" slot="fallback"></og-text> 
          </og-price>
          <og-price regular></og-price>
          <og-price subscription></og-price>
    
        </og-optin-button>
        <og-tooltip placement="bottom">
          <div slot="trigger">
            <og-text key="offerTooltipTrigger"></og-text>
          </div>
          <div slot="content">
            <og-text key="offerTooltipContent"></og-text>
          </div>
        </og-tooltip>
      </div>
      <div style="margin-left: 2.2em">
        <og-text key="offerEveryLabel"></og-text>
        <og-select-frequency>
          <option value="3_1" selected>3 Days</option>
          <option value="1_2">1 Week</option>
          <option value="1_3">1 Month</option>
        </og-select-frequency>
      </div>
    </og-when>

    <og-when test="upsellEligible">
      <og-when test="!upcomingOrderContainsProduct">
      <div class="og-iu-offer">
        <og-text key="upsellButtonLabel"></og-text>
        <og-upsell-button>
          <button type="button">
            <og-text key="upsellButtonContent"></og-text>
            <og-next-upcoming-order></og-next-upcoming-order>
          </button>
        </og-upsell-button>
        <og-upsell-modal>
          <og-text key="upsellModalContent"></og-text>
          <br />

          <og-optout-button>
            <og-text key="upsellModalOptOutLabel"></og-text>
          </og-optout-button>

          <br />

          <og-optin-button>
            <og-text key="upsellModalOptInLabel"></og-text>
          </og-optin-button>
          <br />

          <og-text key="offerEveryLabel"></og-text>
          <og-select-frequency>
            <option value="3_1" selected>3 Days</option>
            <option value="1_2">1 Week</option>
            <option value="1_3">1 Month</option>
          </og-select-frequency>

          <button slot="confirm" class="og-modal__btn og-modal__btn-primary">
            <og-text key="upsellModalConfirmLabel"></og-text>
          </button>
          <button slot="cancel" class="og-modal__btn"><og-text key="upsellModalCancelLabel"></og-text></button>
        </og-upsell-modal>
      </div>
      </og-when>
      <og-when test="upcomingOrderContainsProduct">
        The product is in your next upcomming order
      </og-when>
    </og-when>
    
    `;
  }

  constructor() {
    super();
    this.module = 'pdp';
    this.product = {};
    this.productComponents = [];
    this.fetchOffer = () => 0;
    this.fetchOrders = () => 0;
    this.productHasChangedComponents = () => 0;
    this.setFirstOrderPlaceDate = () => 0;
    this.setProductToSubscribe = () => 0;
    this.productChangeFrequency = () => 0;
  }

  applyTemplate(template) {
    super.applyTemplate(template);
    const { id: variationId, locale } = template;
    this.variationId = variationId;
    this.locale = locale;
    const event = new CustomEvent('template-changed');
    this.dispatchEvent(event);
  }

  updated(changed) {
    if (changed.has('preview')) {
      this.setPreview(this.preview, changed.get('preview'), this);
    }
    this.frequency = this.defaultFrequency;

    if (changed.has('product') && !this.isPreview) {
      onReady(() => this.fetchOffer(this.product.id, DEFAULT_OFFER_MODULE, this));
    }

    if (changed.has('firstOrderPlaceDate') && this.product.id && !this.isPreview) {
      this.setFirstOrderPlaceDate(this.product.id, this.firstOrderPlaceDate);
    }

    if (changed.has('productToSubscribe') && this.product.id && !this.isPreview) {
      this.setProductToSubscribe(this.product.id, this.productToSubscribe);
    }

    if (changed.has('auth') && this.auth && !this.isPreview) {
      this.fetchOrders();
    }

    if (changed.has('productComponents')) {
      const newProductWithComponents = productAndComponents(this.product, this.productComponents);
      const oldProductWithComponents = Object.assign({}, this.product, {
        components: changed.get('productComponents')
      });

      if (!isSameProduct(newProductWithComponents, oldProductWithComponents)) {
        this.productHasChangedComponents(newProductWithComponents, oldProductWithComponents);
      }
    }

    if (
      (changed.has('offerId') ||
        changed.has('autoshipByDefault') ||
        changed.has('location') ||
        changed.has('product')) &&
      this.offerId &&
      this.autoshipByDefault &&
      (this.location === 'cart' || this.isCart) &&
      this.product.id &&
      this.optinProduct &&
      !(this.optedin || []).find(product => isSameProduct(product, this.product))
    ) {
      this.optinProduct(
        {
          ...this.product,
          ...(this.productComponents.length && { components: this.productComponents })
        },
        this.defaultFrequency,
        this
      );
    }
  }

  get isPreview() {
    return this.preview || window.og.previewMode;
  }

  render() {
    return html`
      <slot></slot>
    `;
  }

  get defaultFrequency() {
    const storeFrequency = this.productFrequency || this.productDefaultFrequency;

    if (storeFrequency) {
      return storeFrequency;
    }

    const freq = this.querySelector('og-select-frequency');
    if (freq && freq.currentFrequency) {
      return freq.currentFrequency;
    }

    return this.getOption('defaultFrequency');
  }
}

export const mapStateToProps = (state, ownProps) => ({
  config: state.config,
  auth: state.auth,
  offerId: ((state.productOffer || {})[(ownProps.product || {}).id] || [])[0],
  productFrequency: makeProductFrequencySelector(ownProps.product)(state),
  productDefaultFrequency: makeProductDefaultFrequencySelector((ownProps.product || {}).id)(state),
  ...configSelector(state, ownProps, 'autoshipByDefault', autoshipSelector(state)[(ownProps.product || {}).id]),
  ...(makeOptedoutSelector(ownProps.product)(state) && { autoshipByDefault: false }),
  optedin: optedinSelector(state),
  subscribed: makeOptedinSelector(ownProps.product)(state),
  ...templatesSelector(state)
});

export const ConnectedOffer = connect(mapStateToProps, {
  fetchOffer,
  fetchOrders,
  productHasChangedComponents,
  optinProduct,
  setFirstOrderPlaceDate,
  setProductToSubscribe,
  setPreview
})(Offer);

export default ConnectedOffer;
