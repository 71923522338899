import { css, html } from 'lit-element';
import { connect } from '../core/connect';

import { withProduct } from '../core/resolveProperties';
import { TemplateElement } from '../core/base';
import { makeProductPrepaidShipmentsOptedInSelector, makePrepaidShipmentsSelectedSelector } from '../core/selectors';
import { safeProductId } from '../core/utils';

export class PrepaidData extends withProduct(TemplateElement) {
  static get properties() {
    return {
      ...super.properties,
      shipmentsOptedIn: { type: Number },
      productPlans: { type: Object },
      prepaidShipmentsSelected: { type: Number },
      totalPrice: { type: Boolean, reflect: true, attribute: 'total-price' },
      perDeliveryPrice: { type: Boolean, reflect: true, attribute: 'per-delivery-price' },
      totalSavings: { type: Boolean, reflect: true, attribute: 'total-savings' },
      perDeliverySavings: { type: Boolean, reflect: true, attribute: 'per-delivery-savings' },
      percentageSavings: { type: Boolean, reflect: true, attribute: 'percentage-savings' },
      extraPercentageSavings: { type: Boolean, reflect: true, attribute: 'extra-percentage-savings' },
      numberOfShipments: { type: Boolean, reflect: true, attribute: 'number-of-shipments' }
    };
  }

  static get styles() {
    return css`
      :host {
        display: inline-block;
        text-indent: initial;
      }
    `;
  }

  get value() {
    const realProductId = safeProductId(this.product);
    const plans = this.productPlans[realProductId] || [];

    const targetShipments = this.prepaidShipmentsSelected ? this.prepaidShipmentsSelected : this.shipmentsOptedIn;
    let currentPlan = plans.find(plan => plan.prepaidShipments > 1 && plan.prepaidShipments === targetShipments);

    if (!currentPlan) {
      currentPlan = plans.find(plan => plan.prepaidShipments > 1);
      if (!currentPlan) return '';
    }

    const {
      discountRate,
      subscriptionPrice,
      prepaidShipments,
      regularPrepaidPrice,
      prepaidSavingsPerShipment,
      prepaidSavingsTotal,
      prepaidExtraSavingsPercentage
    } = currentPlan;

    if (this.totalPrice) {
      return regularPrepaidPrice;
    }

    if (this.perDeliveryPrice) {
      return subscriptionPrice;
    }

    if (this.totalSavings) {
      return prepaidSavingsTotal;
    }

    if (this.perDeliverySavings) {
      return prepaidSavingsPerShipment;
    }

    if (this.percentageSavings) {
      return discountRate;
    }

    if (this.extraPercentageSavings) {
      return prepaidExtraSavingsPercentage;
    }

    if (this.numberOfShipments) {
      return prepaidShipments;
    }

    return '';
  }

  render() {
    const value = this.value;
    if (value)
      return html`
        <slot name="prepend"></slot>
        ${value}
        <slot name="append"></slot>
      `;

    return html`
      <slot name="fallback"></slot>
    `;
  }
}
const mapStateToProps = (state, ownProps) => ({
  shipmentsOptedIn: makeProductPrepaidShipmentsOptedInSelector(ownProps.product)(state),
  prepaidShipmentsSelected: makePrepaidShipmentsSelectedSelector(ownProps.product)(state),
  productPlans: state.productPlans
});

const ConnectedPrepaidData = connect(mapStateToProps)(PrepaidData);

export { ConnectedPrepaidData };
