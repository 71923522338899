import { html } from 'lit-element';
import { concludeUpsell, createIu } from '../core/actions';
import { connect } from '../core/connect';
import { auth, defaultFrequency } from '../core/props';
import { makeOptedinSelector, makeProductFrequencySelector, configSelector } from '../core/selectors';
import { withProduct } from '../core/resolveProperties';
import { TemplateElement } from '../core/base';

export class UpsellModal extends withProduct(TemplateElement) {
  static get properties() {
    return {
      ...super.properties,
      defaultFrequency,
      auth,
      subscribed: { type: Boolean, attribute: false },
      frequency: { type: String, attribute: false },
      nextUpcomingOrder: { type: Object, attribute: false },
      show: { type: Boolean, attribute: 'show' },
      offerId: { type: String }
    };
  }

  constructor() {
    super();
    this.createIu = () => 0;
    this.concludeUpsell = () => 0;
  }

  render() {
    return html`
      <og-modal ?show=${this.show} @close=${() => this.close()} @confirm=${() => this.confirm()}>
        <div slot="content">
          <slot>
            <slot name="content">
              <og-text key="upsellModalContent"></og-text>
            </slot>
            <slot name="offer">
              <br />

              <og-optout-button>
                <slot name="opt-out-label">
                  <og-text key="upsellModalOptOutLabel" slot="label"></og-text>
                </slot>
              </og-optout-button>
              <br />
              <og-optin-button default-frequency=${this.defaultFrequency}>
                <slot name="opt-in-label">
                  <og-text key="upsellModalOptInLabel" slot="label"></og-text>
                </slot>
              </og-optin-button>
              <br />
              <slot name="every-label">
                <og-text key="offerEveryLabel"></og-text>
              </slot>
              <og-select-frequency default-frequency=${this.defaultFrequency}></og-select-frequency>
            </slot>
          </slot>
        </div>
        <span slot="confirm">
          <slot name="confirm"><og-text key="upsellModalConfirmLabel"></og-text></slot
        ></span>
        <span slot="cancel">
          <slot name="cancel">
            <og-text key="upsellModalCancelLabel"></og-text>
          </slot>
        </span>
      </og-modal>
    `;
  }

  set defaultFrequency(val) {
    this._defaultFrequency = val;
  }

  get defaultFrequency() {
    const freq = this.querySelector('og-select-frequency');
    if (freq) {
      return freq.defaultFrequency;
    }

    return this._defaultFrequency;
  }

  confirm() {
    this.createIu(
      this.product,
      this.nextUpcomingOrder.public_id,
      1,
      this.subscribed,
      this.frequency || this.defaultFrequency
    );
    this.close();
  }

  close() {
    this.concludeUpsell();
    this.removeAttribute('show');
  }
}

export const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  offerId: state.offerId,
  subscribed: makeOptedinSelector(ownProps.product)(state),
  frequency: makeProductFrequencySelector(ownProps.product)(state),
  ...configSelector(state, ownProps, 'defaultFrequency'),
  nextUpcomingOrder: state.previewUpsellOffer ? { public_id: 'preview-order-id' } : state.nextUpcomingOrder,
  isPreview: state.previewUpsellOffer
});

export const ConnectedUpsellModal = connect(mapStateToProps, {
  concludeUpsell,
  createIu
})(UpsellModal);

export default UpsellModal;
