/* eslint-disable no-fallthrough */
import { throttle } from 'throttle-debounce';
import * as constants from './constants';
import { isSameProduct, kebabCase } from './selectors';
import { saveState } from './localStorage';

export const dispatchEvent = (name, detail, el = document) =>
  el.dispatchEvent(
    new CustomEvent(name, {
      detail
    })
  );

export const dispatchOptinChangedEvent = optedIn => ({
  payload: { product: { id: productId, components } = {} } = {}
} = {}) =>
  setTimeout(
    () =>
      dispatchEvent('optin-changed', {
        productId,
        components,
        optedIn
      }),
    0
  );

export const conditionals = [
  {
    expressions: [
      ({ type } = {}) => type === constants.OPTIN_PRODUCT,
      ({ type } = {}) => type === constants.PRODUCT_CHANGE_FREQUENCY
    ],
    fn: dispatchOptinChangedEvent(true)
  },
  {
    expressions: [({ type } = {}) => type === constants.OPTOUT_PRODUCT],
    fn: dispatchOptinChangedEvent(false)
  }
];

export const dispatchMiddleware = store => next => action => {
  const state = store.getState();
  conditionals.forEach(conditional => {
    if (conditional.expressions.some(expression => expression(action, state))) conditional.fn(action);
  });

  next(action);
};

/**
 * Middleware that forwards sensitive actions to DOM events
 *  events:
 *     - og-receive-offer
 *     - og-optout-product
 *     - og-optin-product
 *     - og-product-change-frequency
 *  event.details contains the action payload
 *  event.details corresponds to the offer DOM element if available or document
 *
 * If event.preventDefault() is called, the action being fired will not change the state.
 * @param {*} store
 * @returns
 */
export const offerEvents = store => next => action => {
  let ev;

  switch (action.type) {
    // event: og-receive-offer
    case constants.RECEIVE_OFFER:
    // event: og-optout-product
    case constants.OPTOUT_PRODUCT:
    // event: og-optin-product
    case constants.OPTIN_PRODUCT:
    // event: og-product-change-frequency
    case constants.PRODUCT_CHANGE_FREQUENCY:
      ev = new CustomEvent(`og-${action.type.toLowerCase().replace(/_/g, '-')}`, {
        bubbles: true,
        cancelable: true,
        detail: action.payload
      });
      (action.payload?.offer || document).dispatchEvent(ev);
      break;
    default:
  }

  if (!ev?.defaultPrevented) next(action);
};

export const localStorageMiddleware = store => next => action => {
  next(action);

  const callToSave = throttle(500, () => {
    saveState({
      ...store.getState()
    });
  });

  if (action.type !== constants.LOCAL_STORAGE_CHANGE) {
    callToSave();
  }
};
